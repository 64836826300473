<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card>
          <v-card-title class="title">
            <!-- <v-icon>layers</v-icon> -->
            Mappping Param
            <v-spacer></v-spacer>
            <add-button permission="subject-read" @action="form.dialog = true"
              >New Mapping Param
            </add-button>
          </v-card-title>

          <v-card flat>
            <v-card outlined>
              <v-card-title class="title">
                <v-flex xs4>
                  <v-text-field
                    outlined
                    dense
                    hint="Atleast 3 characters"
                    color="primary"
                    class="pa-0"
                    label="Search By Name"
                    v-model="searchAH"
                    @input="searchData"
                    append-icon="search"
                  />
                </v-flex>
              </v-card-title>
            </v-card>
          </v-card>

          <v-data-table
            :headers="headers"
            :items="form.items.data"
            :footer-props="footerProps"
            :options.sync="pagination"
            :server-items-length="form.items.meta.total"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td>{{ ++index }}</td>
                <td class="text-xs-left">{{ item.name }}</td>
                <td style="text-align: right" class="text-xs-right">
                  <span v-if="item.company_id">
                    <edit-button
                      permission="subject-read"
                      @agree="form.edit(item)"
                    />
                    <delete-button
                      permission="section-create"
                      @agree="form.delete(item.id)"
                    />
                  </span>
                </td>
              </tr>
            </template>
            <template slot="no-data">
              <v-alert :value="true" color="error" icon="warning">
                No record found.
              </v-alert>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="form.dialog" persistent max-width="500px">
      <v-card>
        <v-card-title class="title primary white--text"
          >Account Group Information</v-card-title
        >
        <v-card-text>
          <v-form
            ref="form"
            @submit.prevent="save"
            @keydown.native="form.errors.clear($event.target.name)"
            @keyup.enter="save"
            lazy-validation
          >
            <v-container grid-list-md>
              <v-layout row wrap>
                <v-flex xs12>
                  <v-text-field
                    outlined
                    dense
                    v-model="form.name"
                    name="name"
                    label="Name"
                    :error-messages="form.errors.get('name')"
                    :height="25"
                    autocomplete="off"
                  />
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="warning"
            outlined
            text
            @click="(form.dialog = false), form.reset()"
            >Cancel</v-btn
          >
          <v-btn color="success" outlined text @click="save">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import Form from '@/library/Form';

const NepaliDate = require('nepali-date');
const nd = new NepaliDate();

export default {
  data: () => ({
    form: new Form(
      {
        name: '',
      },
      '/api/account/inventory/mapping-param'
    ),
    searchAH: null,
    search: null,
    isLoading: false,
    rowsPerPageItems: [5, 10, 25, 50, 75, 100],
    pagination: {
      rowsPerPage: 10,
      sortBy: 'id',
    },
    entries: [],
    accountHeads: [],
    headers: [
      { text: '#', align: 'left', sortable: false, value: 'id', width: '20px' },
      {
        text: 'Name',
        value: 'name',
        align: 'left',
        width: '30%',
        sortable: false,
      },
      {
        text: 'Actions',
        value: 'action',
        sortable: false,
        align: 'right',
        width: 120,
      },
    ],
    footerProps: {
      'items-per-page-options': [10, 25, 50, 100, -1],
    },
  }),

  computed: {
    ...mapState(['batch']),
    items() {
      return this.entries.map((entry) => {
        return { Name: entry.name, id: entry.id };
      });
    },
  },

  watch: {
    pagination: function () {
      this.get();
    },
    batch: function (value) {
      this.get();
    },
    search(val) {
      if (!val) return;
      // if (this.isLoading) return;

      this.isLoading = true;
      this.$rest
        .get('/api/account-head?search=' + val)
        .then(({ data }) => {
          // this.entries = data.data
          this.entries = data.data.map((res) => {
            return { name: res.name, id: res.id };
          });
        })
        .catch((err) => {})
        .finally(() => (this.isLoading = false));
    },
  },

  methods: {
    queryString() {
      let json = this.pagination;
      return (
        '?' +
        Object.keys(json)
          .map(function (key) {
            if (![null, undefined].includes(json[key]))
              return (
                encodeURIComponent(key) + '=' + encodeURIComponent(json[key])
              );
          })
          .join('&') +
        '&search=' +
        this.searchAH
      );
    },

    get(params) {
      let query = [null, undefined].includes(params)
        ? this.queryString()
        : params;
      this.form.get(null, query).then(({ data }) => {
        this.pagination.totalItems = data.meta.total;
        this.accountHeads = data.data.map((item) => ({
          value: item.id,
          text: item.name,
        }));
      });
    },

    save() {
      // this.form.parent_id = this.form.parent_id.id;
      this.form.store();
    },
    searchData: function (v) {
      if (v.length > 2 || !v.length) this.get();
    },
  },
};
</script>
<style lang="scss"></style>
